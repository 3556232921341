/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import './status.css'
import './status-svg-anim.css'
import '../../_4threal/helpers/fonts/Kanit/Kanit-Bold.ttf'

type statusProps = {
  markerImg?: string
  isLoading: boolean
  isScanning: boolean
  isProcessing: boolean
  isDemo: boolean
  needsTap: boolean
  isError: string
  contentVisible: boolean
  allowUnanchored: boolean
  isPublished?: boolean
  progress?: number
  hasTapped?: () => void
  viewExp?: () => void
  videoUrl?: string
}

const StatusOverlay: React.FC<statusProps> = (props => {
  const {
    markerImg,
    isLoading,
    needsTap,
    isError,
    isPublished,
    isDemo,
    progress = 0,
    hasTapped = () => {},
    videoUrl,
    isScanning,
    // allowUnanchored,
    // isProcessing,
    // viewExp,
    // contentVisible,
  } = props

  const [progressLate, setProgressLate] = useState(0)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [animationContainerScale, setAnimationContainerScale] = useState('')
  const assetLoad = useRef<any[]>([false, false])

  const getCss = (className) => //start all animations at the exact same time "assetLoad.current" gets updated
    assetLoad.current.every(x => x === true)
    ? `${className} ${className}-anim`
    : `${className} opacity-zero`

  useEffect(() => {
    const updateDimensions = () => {
      setAnimationContainerScale(window.innerWidth < 400 ? '' : 'animation-container-scaled-up')
    }

    updateDimensions() //run once on mount
    window.addEventListener("resize", updateDimensions)
    return () => window.removeEventListener("resize", updateDimensions)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setProgressLate(progress)
    }, 300);
    if(progress >= 100) {
      setTimeout(() => {
        setHasLoaded(true)
      }, 300);
    }
  }, [progress])

  const tryStartAnim = async (name) => {
    switch (name) {
      case 'marker': assetLoad.current = [true, assetLoad.current[1]]; break;
      case 'hand':   assetLoad.current = [assetLoad.current[0], true]; break;
    }
  }

  const handleTap = () => {
    if(needsTap && !isLoading) {
      hasTapped()
    }
  }

  return (
    <>
      {isError === '' && (isLoading || needsTap)
      ? (isPublished || isDemo) &&
        <div className="mindar-ui-overlay mindar-ui-loading">
          <div className="stat-window">
            <div className={`animation-container ${animationContainerScale}`}>
              <div style={{ transform: 'scale(1.15)' }}> {/* You can manually adjust the SVG+labels size here */}
                <svg className='hold-phone-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                  <rect width="10"  height="70" rx="0" ry="0" fill="#008dc7" className={getCss('top-left')} />
                  <rect width="140" height="10" rx="0" ry="0" fill="#008dc7" className={getCss('top-center')} />
                  <rect width="10"  height="70" rx="0" ry="0" fill="#008dc7" className={getCss('top-right')} />
                  <rect width="10"  height="70" rx="0" ry="0" fill="#008dc7" className={getCss('bottom-left')} />
                  <rect width="140" height="10" rx="0" ry="0" fill="#008dc7" className={getCss('bottom-center')} />
                  <rect width="10"  height="70" rx="0" ry="0" fill="#008dc7" className={getCss('bottom-right')} />
                </svg>
                <div className='hold-phone-labels'>
                  <div className={getCss('hold')}>HOLD</div>
                  <div className={getCss('phone')}>PHONE</div>
                  <div className={getCss('over-image')}>OVER IMAGE</div>
                </div>
              </div>
              <div className='hand-scan-container'>
                <div className={getCss('for-best-results')}>For Best Results,<br/>Keep Phone Over Image</div>
                <img onLoad={() => tryStartAnim('marker')} className={getCss('marker')} src={markerImg} alt=""/>
                <img onLoad={() => tryStartAnim('hand')} className={getCss('scan-hand')} src='/media/splash/scan_hand.png' alt=""/>
                {videoUrl !== '' && <video className={getCss('scan-video') + ' mask'} src={videoUrl} autoPlay loop muted playsInline/> }
              </div>
            </div>
            <div className='progress-container'>
              <div className={`progresstext fade ${hasLoaded ? 'opacity-one'  : 'opacity-zero'}`}>PRESS PLAY</div>
              <div className={`progressbar  fade ${hasLoaded ? 'opacity-zero' : 'opacity-one'}`}><ProgressBar now={progressLate}/></div>
              <img onClick={handleTap} className={`play-img scale ${hasLoaded ? 'scale-one' : 'scale-zero'}`} src="/media/splash/play_button.svg" alt=''/><br/>
              <img className='skanteq-logo' src="/media/splash/skanteq_logo.png" alt=''/>
            </div>
          </div>
        </div>
      : isError === 'marker-not-attributed'
        ? <div style={{ color: 'white', marginTop: '100px', fontSize: '28px' }}>
            This Experience is under construction. You can enjoy it after it has been published.<br></br>
            Until then, check out <a href="https://www.scanjunkie.com" >scanjunkie.com</a>
          </div>
        : <>{isError}</>
      }

      {isScanning && !needsTap && !isLoading
      ? <div className="mindar-ui-overlay mindar-ui-scanning">
          <div className='scanning'>
            <div style={{ position: 'absolute', bottom: '20px', color: 'white', textAlign: 'center' }}>
              For Best Results,<br/>Keep Phone Over Image
            </div>
          </div>
          {/*
          {isError === ''
          ? <div className="scanning">
              {!allowUnanchored && (<img className="instructions" src="/media/ar/instructions.svg" alt="" />)}
              {!allowUnanchored && (<img className="frame" src="/media/ar/frame.svg" alt="" />)}
              <img src="/media/ar/hand.svg" className='hand' alt="" />
              <img className='checkImg' src="/media/ar/checkbox.svg" alt="" />
              <img className='markerImg' src={markerImg} alt="" />
            </div>
          : <div className="scanning">
              <div style={{ color: 'white', marginTop: '100px', fontSize: '28px' }}>{isError}</div>
            </div>
          }
          */}
        </div>
      : <div style={{ textAlign: 'center' }} className="scanning">
          {/* <div style={{ color: 'white', margin: '30px', fontSize: '28px' }}>{isError}</div> */}
        </div>
      }
    </>
  )
})

export default StatusOverlay

// // Use For Animation Reference
// const firstImageOpacity = progress <= 1 ? 0 : progress >= 60 ? 1 : (progress - 40) / 100
// <img style={{ height: '100%', opacity: firstImageOpacity, transition: 'opacity 0.5s ease-in-out' }} src="/media/splash/loading.svg" alt="" />