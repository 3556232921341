import { Matrix4, Vector3, Quaternion, Scene, PlaneGeometry, MeshStandardMaterial, DoubleSide, Mesh, WebGLRenderer, PCFSoftShadowMap, PerspectiveCamera, Group, sRGBEncoding } from "three";
import * as tf from '@tensorflow/tfjs';
//import { CSS3DRenderer } from '../libs/CSS3DRenderer.js';
import {CSS3DRenderer} from 'three/examples/jsm/renderers/CSS3DRenderer'
import { Controller } from "../../mind-ar-js/src/image-target/controller-adaptive.js";
// import { DeviceOrientationControls } from './DeviceOrientationControls.js';
import { AppLog } from "../../_4threal/helpers/AppLog";

const cssScaleDownMatrix = new Matrix4();
cssScaleDownMatrix.compose(new Vector3(), new Quaternion(), new Vector3(0.001, 0.001, 0.001));
// Define custom events
const targetFoundEvent = new Event('targetFound');
const targetLostEvent = new Event('targetLost');
// const errorEvent = new CustomEvent('error', { detail: 'There was an error accesing the camera.' });
// const readyEvent = new Event('ready'); // omitting due to eslint warning

const invisibleMatrix = new Matrix4().set(0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0,0,1);
let defaultMatrix = new Matrix4();
export class MindARThree {
  constructor({
    container, imageTargetSrc, maxTrack, isVisible = false, isPreview = false, cameraDistance = 3, uiLoading = "yes", uiScanning = "yes", uiError = "yes",
    filterMinCF = null, filterBeta = null, warmupTolerance = null, missTolerance = null,
    userDeviceId = null, environmentDeviceId = null, sceneId = ''
  }) {

    this.container = container;
    this.imageTargetSrc = imageTargetSrc;
    this.maxTrack = maxTrack;
    this.filterMinCF = filterMinCF;
    this.filterBeta = filterBeta;
    this.warmupTolerance = warmupTolerance;
    this.missTolerance = missTolerance;
    this.userDeviceId = userDeviceId;
    this.environmentDeviceId = environmentDeviceId;

    this.shouldFaceUser = false;

    this.scene = new Scene();
    this.cssScene = new Scene();

    // Throughout 2024, we were encountering a glitchy rendering phenomenon called "z-fighting".
    // By adding logarithmicDepthBuffer to the WebGLRenderer config, this issue appears to be fixed.
    // More info here: https://stackoverflow.com/questions/40328722/how-can-i-solve-z-fighting-using-three-js/56792475#56792475

    // Also, we discovered that adding logarithmicDepthBuffer=true caused layering issues with chroma key videos.
    // This issue was fixed by adding some configurations to the shader.js used by the chroma key video shaders.
    // TL;DR: all "#include" and "precision" phrases were added to address this z-layering problem.
    // See more info here: https://discourse.threejs.org/t/shadermaterial-render-order-with-logarithmicdepthbuffer-is-wrong/49221/3

    // For safety, we can use the logarithmicDepthBuffer configuration only when we need it
    // if(sceneId === 'bxLAeEtEDhEnJCu9zS5Tma-s') {
    //   this.renderer = new WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true, });
    // } else {
    //   console.log('Setting up WebGLRenderer with logarithmicDepthBuffer')
    //   this.renderer = new WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true, logarithmicDepthBuffer: true });
    // }

    this.renderer = new WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true, logarithmicDepthBuffer: true });

    this.cssRenderer = new CSS3DRenderer({ antialias: true });
    this.renderer.outputEncoding = sRGBEncoding;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.camera = new PerspectiveCamera();
    this.anchors = [];

    this.renderer.domElement.style.position = 'absolute';
    this.renderer.domElement.id = 'main3jsScene';
    this.cssRenderer.domElement.style.position = 'absolute';
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap;
    this.container.appendChild(this.renderer.domElement);
    this.container.appendChild(this.cssRenderer.domElement);
    this.isVisible = isVisible;
    this.isPreview = isPreview;
    this.cameraDistance = cameraDistance;
    this.controls = null;
    window.addEventListener('resize', this.resize.bind(this));
  }

  setVisible(viz = true) {
    this.isVisible = viz;
    this.repositionCamera();
    AppLog ("set visible", this.isVisible)
  }
  async start() {
    //this.ui.showLoading();
    await this._startVideo();
    await this._startAR();
  }

  stop() {
    if (this.controller && this.controller.processingVideo){
      this.controller.stopProcessVideo();
      const tracks = this.video.srcObject.getTracks();
      tracks.forEach(function (track) {
        track.stop();
      });
      this.video.remove();
    }
  }

  switchCamera() {
    this.shouldFaceUser = !this.shouldFaceUser;
    this.stop();
    this.start();
  }

  addAnchor(targetIndex) {
    const group = new Group();
    group.visible = this.isVisible;
    group.matrixAutoUpdate = false;
    const anchor = { group, targetIndex, onTargetFound: null, onTargetLost: null, onTargetUpdate: null, css: false, visible: false };
    this.anchors.push(anchor);
    this.scene.add(group);
    return anchor;
  }

  addCSSAnchor(targetIndex) {
    const group = new Group();
    group.visible = false;
    group.matrixAutoUpdate = false;
    const anchor = { group, targetIndex, onTargetFound: null, onTargetLost: null, onTargetUpdate: null, css: true, visible: false };
    this.anchors.push(anchor);
    this.cssScene.add(group);
    return anchor;
  }
  _startVideo() {
    return new Promise((resolve, reject) => {

      let message = ""
      this.video = document.createElement('video');

      this.video.setAttribute('muted', true);
      this.video.setAttribute('playsinline', true);
      this.video.style.position = 'absolute'
      this.video.style.zIndex = '-2'
      this.video.style.width = '100%';
      this.video.style.height = '100%';
      this.video.style.objectFit = 'cover';
      this.container.appendChild(this.video);

      if (this.isPreview){

        this.video.setAttribute('autoplay', true);
        this.video.setAttribute('loop', true);
        this.video.src = "/media/videos/cambg.mp4";
        this.video.addEventListener('canplay', () => {
          //this.video.play();
          resolve();
          return;
        });

        this.video.addEventListener('error', () => {
          message = 'Error loading the video file.';
          reject(message);
          return;
        });



      }else{

        navigator.mediaDevices.enumerateDevices().then((devices) => {
          if (!devices.length) {
            const errorMessage = 'No camera devices found.';
            document.dispatchEvent(new CustomEvent('error', { detail: errorMessage }));
            reject(errorMessage)
            return;
          };

        }).catch((err) => {
          AppLog("enumerateDevices error", err);
          const errorMessage = 'Cant access camera devices.';
          document.dispatchEvent(new CustomEvent('error', { detail: errorMessage }));
          reject(errorMessage)
          return;
        });

        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          //this.ui.showCompatibility();
          const errorMessage = 'No camera devices found.';
          document.dispatchEvent(new CustomEvent('error', { detail: errorMessage }));
          reject(errorMessage)
          return;
        }

        const constraints = {
          audio: false,
          video: {}
        };
        if (this.shouldFaceUser) {
          if (this.userDeviceId) {
            constraints.video.deviceId = { exact: this.userDeviceId };
          } else {
            constraints.video.facingMode = 'user';
          }
        } else {
          if (this.environmentDeviceId) {
            constraints.video.deviceId = { exact: this.environmentDeviceId };
          } else {
            constraints.video.facingMode = 'environment';
          }
        }

        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          this.video.addEventListener('loadedmetadata', () => {

            resolve();
            return;
          });
          this.video.srcObject = stream;
        }).catch((err) => {
          AppLog(err);
          if (err.name === 'NotAllowedError') {
            const errorMessage = 'Camera access was denied.  Please enable camera permissions in your browser settings.';
            document.dispatchEvent(new CustomEvent('error', { detail: errorMessage }));
            reject (errorMessage);
            return;
          } else if (err.name === 'NotFoundError') {
            const errorMessage = 'No camera found on this device.';
            document.dispatchEvent(new CustomEvent('error', { detail: errorMessage }));
            reject (errorMessage);
            return;
          } else {
            const errorMessage = 'An unexpected error occurred while trying to access the camera';
            document.dispatchEvent(new CustomEvent('error', { detail: errorMessage }));
            reject (errorMessage);
            return;
          }


        });

        this.video.setAttribute('autoplay', true);
      }

    });
  }

cancelAnimation(){
  if (this.animationFrameId) {
    cancelAnimationFrame(this.animationFrameId);
    this.animationFrameId = null; // Clear the stored frame ID
  }
  if (this.controls){
    this.controls.disconnect();
  }
  this.camera.position.set(0, 0, 0);
  this.camera.lookAt(new Vector3(0, 0, 0));
  this.camera.updateProjectionMatrix();
// Step 2: Reset the camera position
// Replace x, y, and z with the coordinates to which you want to reset the camera
  //this.camera.position.set(0, 0, 0);
 // this.camera.near = 0.01;
  // Optionally, reset the camera orientation
 // this.camera.updateProjectionMatrix();
}
  // Function to reposition the camera
repositionCamera() {
  // AppLog('repositionCamera', this.isVisible)

  if (!this.isVisible){
    return;
  }

  // Remove any existing animation frame request

  const animateCamera = () => {

    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }

    const time = Date.now() * 0.001; // time in seconds

      // Oscillate camera position side to side and up and down
      const amplitudeX = 0.8; // adjust the amplitude as needed
      const amplitudeY = 0.6; // adjust the amplitude as needed
      const frequency = 1; // adjust the frequency as needed

      // Calculate new position
      const posX = Math.sin(time * frequency) * amplitudeX;
      const posY = Math.cos(time * frequency) * amplitudeY;

      // Update camera position
      this.camera.position.set(posX, posY, 1.8); // Adjust Z as needed


    // Orient the camera to look at the origin
    this.camera.lookAt(new Vector3(0, 0, 0));
    this.camera.near = 0.01;
    this.camera.updateProjectionMatrix();

    // Request the next frame
    this.animationFrameId = requestAnimationFrame(animateCamera);
  }
  //this.controls = new DeviceOrientationControls(this.camera, this.cameraDistance);
  // Start the animation loop
  const animate = () => {
    this.animationFrameId = requestAnimationFrame(animate);
    //this.controls.connect();
    // Update the controls based on device orientation
    //this.controls.update();

    //controls.resetCamera();
    // Render your scene with the updated camera
    //renderer.render(scene, camera);
  }

  // Initial call to start the loop

  // Set the camera position
  if (this.isPreview){

    const planeGeometry = new PlaneGeometry(10, 10); // Adjust size as needed
    const planeMaterial = new MeshStandardMaterial({ color: 0x5A5A5A, side: DoubleSide });
    let planeMesh = new Mesh(planeGeometry, planeMaterial);
    planeMesh.position.set(0, 0, -3);
    planeMesh.name = 'plane';
    planeMesh.lookAt(this.camera.position);
    //planeMesh.rotation.y = Math.PI;
    planeMesh.receiveShadow = true;
    this.camera.add(planeMesh);
    //AppLog(this.camera)
    this.scene.add(this.camera);
    animateCamera(); // Example: 10 units back on the Z-axis
  }else{
    this.camera.position.set(0, 0, 3); // Example: 10 units back on the Z-axis
    this.camera.lookAt(new Vector3(0, 0, 0));
    this.camera.near = 0.01;
    this.camera.updateProjectionMatrix()
    animate();
  }

  /*}else{
    this.camera.position.set(0, 0, 4); // Example: 10 units back on the Z-axis
    this.camera.lookAt(new Vector3(0, 0, 0));
    this.camera.near = 0.01;
    this.camera.updateProjectionMatrix()
  }*/

  // Orient the camera to look at the origin

  //const controls = new DeviceOrientationControls(this.camera);
  //controls.update();
}

  _startAR() {
    return new Promise(async (resolve, reject) => {
      const video = this.video;
      // const container = this.container; // omitting due to eslint warning

      this.controller = new Controller({
        inputWidth: video.videoWidth,
        inputHeight: video.videoHeight,
        filterMinCF: this.filterMinCF,
        filterBeta: this.filterBeta,
        warmupTolerance: this.warmupTolerance,
        missTolerance: this.missTolerance,
        maxTrack: this.maxTrack,
        onUpdate: (data) => {
          if (data.type === 'updateMatrix') {
            AppLog('updateMatrix', this.isVisible)
            const { targetIndex, worldMatrix } = data;

            for (let i = 0; i < this.anchors.length; i++) {


              if (this.anchors[i].targetIndex === targetIndex) {
                if (this.anchors[i].css) {
                  this.anchors[i].group.children.forEach((obj) => {
                    obj.element.style.visibility = worldMatrix === null ? "hidden" : "visible";
                  });
                } else {
                  this.anchors[i].group.visible = worldMatrix !== null;
                }

                if (this.isVisible){
                  const anchor = this.anchors[i];
                  anchor.group.visible = true; // Always visible
                }

                if (worldMatrix !== null) {
                  let m = new Matrix4();
                  m.elements = [...worldMatrix];
                  m.multiply(this.postMatrixs[targetIndex]);
                  if (this.anchors[i].css) {
                    m.multiply(cssScaleDownMatrix);
                  }
                  this.anchors[i].group.matrix = m;

                } else {
                  if (this.isVisible){
                    this.anchors[i].group.matrix = defaultMatrix;
                  }else{
                    this.anchors[i].group.matrix = invisibleMatrix;
                  }

                }

                if (this.anchors[i].visible && worldMatrix === null) {
                  this.anchors[i].visible = false;
                  if (this.anchors[i].onTargetLost) {
                    this.anchors[i].onTargetLost();
                  }
                }

                if (!this.anchors[i].visible && worldMatrix !== null) {
                  this.anchors[i].visible = true;
                  if (this.anchors[i].onTargetFound) {
                    this.anchors[i].onTargetFound();
                  }
                }

                if (this.anchors[i].onTargetUpdate) {
                  this.anchors[i].onTargetUpdate();
                }
              }
            }

            let isAnyVisible = this.anchors.reduce((acc, anchor) => {
              return acc || anchor.visible;
            }, false);
            if (isAnyVisible) {
              // AppLog("target found");
               // Example: 10 units back on the Z-axis
              document.dispatchEvent(targetFoundEvent);
              this.cancelAnimation();
              //this.ui.hideScanning();
            } else {
              // AppLog("target lost");
              document.dispatchEvent(targetLostEvent);
              //this.repositionCamera();
              //this.ui.showScanning();
            }
          }
        }
      });

      this.resize();
      AppLog(this.imageTargetSrc)
      const { dimensions: imageTargetDimensions } = await this.controller.addImageTargetsFromBuffer(this.imageTargetSrc);

      this.postMatrixs = [];
      for (let i = 0; i < imageTargetDimensions.length; i++) {
        const position = new Vector3();
        const quaternion = new Quaternion();
        const scale = new Vector3();
        const [markerWidth, markerHeight] = imageTargetDimensions[i];
        position.x = markerWidth / 2;
        position.y = markerWidth / 2 + (markerHeight - markerWidth) / 2;
        scale.x = markerWidth;
        scale.y = markerWidth;
        scale.z = markerWidth;
        const postMatrix = new Matrix4();
        postMatrix.compose(position, quaternion, scale);
        this.postMatrixs.push(postMatrix);
      }

      await this.controller.dummyRun(this.video);
      //this.ui.hideLoading();
      //this.ui.showScanning();

      //this.controller.processVideo(this.video);
      resolve();
    });
  }

  startProcessing(){
    this.controller.processVideo(this.video);
  }

  resize() {
    const { renderer, cssRenderer, camera, container, video } = this;
    // AppLog('resizeisvisible', this.isVisible)
    if (!video) return;
    if (!this.controller) return;

    this.video.style.top = '0'
      this.video.style.left = '0'
      this.video.style.transform = ''
    this.video.setAttribute('width', this.video.videoWidth);
    this.video.setAttribute('height', this.video.videoHeight);

    let vw, vh; // display css width, height
    const videoRatio = video.videoWidth / video.videoHeight;
    const containerRatio = container.clientWidth / container.clientHeight;
    if (videoRatio > containerRatio) {
      vh = container.clientHeight;
      vw = vh * videoRatio;
    } else {
      vw = container.clientWidth;
      vh = vw / videoRatio;
    }

    const proj = this.controller.getProjectionMatrix();

    // TODO: move this logic to controller
    // Handle when phone is rotated, video width and height are swapped
    const inputRatio = this.controller.inputWidth / this.controller.inputHeight;
    let inputAdjust;
    if (inputRatio > containerRatio) {
      inputAdjust = this.video.width / this.controller.inputWidth;
    } else {
      inputAdjust = this.video.height / this.controller.inputHeight;
    }
    let videoDisplayHeight;
    let videoDisplayWidth;
    if (inputRatio > containerRatio) {
      videoDisplayHeight = container.clientHeight;
      videoDisplayHeight *= inputAdjust;
    } else {
      videoDisplayWidth = container.clientWidth;
      videoDisplayHeight = videoDisplayWidth / this.controller.inputWidth * this.controller.inputHeight;
      videoDisplayHeight *= inputAdjust;
    }
    let fovAdjust = container.clientHeight / videoDisplayHeight;

    // const fov = 2 * Math.atan(1 / proj[5] / vh * container.clientHeight) * 180 / Math.PI; // vertical fov
    const fov = 2 * Math.atan(1 / proj[5] * fovAdjust) * 180 / Math.PI; // vertical fov
    const near = proj[14] / (proj[10] - 1.0);
    const far = proj[14] / (proj[10] + 1.0);
    // const ratio = proj[5] / proj[0]; // (r-l) / (t-b) // omitting due to eslint warning

    camera.fov = fov;
    camera.near = near;
    camera.far = far;
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();

    video.style.top = (-(vh - container.clientHeight) / 2) + "px";
    video.style.left = (-(vw - container.clientWidth) / 2) + "px";
    video.style.width = vw + "px";
    video.style.height = vh + "px";

    const canvas = renderer.domElement;
    const cssCanvas = cssRenderer.domElement;

    canvas.style.position = 'absolute';
    canvas.style.left = 0;
    canvas.style.top = 0;
    canvas.style.width = container.clientWidth + 'px';
    canvas.style.height = container.clientHeight + 'px';

    cssCanvas.style.position = 'absolute';
    cssCanvas.style.left = 0;
    cssCanvas.style.top = 0;
    cssCanvas.style.width = container.clientWidth + 'px';
    cssCanvas.style.height = container.clientHeight + 'px';

    renderer.setSize(container.clientWidth, container.clientHeight);
    cssRenderer.setSize(container.clientWidth, container.clientHeight);

    this.repositionCamera();


  }
}

if (!window.MINDAR) {
  window.MINDAR = {};
}
if (!window.MINDAR.IMAGE) {
  window.MINDAR.IMAGE = {};
}

window.MINDAR.IMAGE.MindARThree = MindARThree;
//window.MINDAR.IMAGE.THREE = THREE;
window.MINDAR.IMAGE.tf = tf;
